<template>
  <div class="login-container">
    <div class="header">
      <div class="title">
        Truckoo Login
      </div>
    </div>

    <div class="login">
      <div class="label">
        Email
      </div>
      <div class="element" style="margin-bottom: 20px">
        <styled-input v-model="username" :validFn="usernameValidFn"/>
      </div>
      <div class="label">
        Password
      </div>
      <div class="element">
        <styled-input v-model="password" :type="'password'" :validFn="v => true" @complete="login()"/>
      </div>
      <div 
       class="forgot-password"
       @click="$router.push('/reset-password')">
        Forgot Password
      </div>

      <div class="submit">
        <div class="button" :class="{ disabled: !dataValid }" @click="login()">
          Login
        </div>
      </div>

      <!-- <div class="signup-container">
        {{ $t("users.login.noAccount") }}
        <div class="signup" @click="$router.push('/signup')">
          {{ $t("users.login.signup") }}
        </div>
      </div> -->
    </div>

  </div>
</template>

<script>
import authService from '@/services/auth-service.js'

import StyledInput from '@/components/utility/StyledInput.vue'

const LOCAL_STORAGE_USERNAME_KEY = '[truckoo::username]'

export default {
  name: 'Login',
  components: {
    StyledInput,
  },
  data() {
    return {
      username: '',
      password: ''
    }
  },
  mounted() {
    const storedUsername = localStorage.getItem(LOCAL_STORAGE_USERNAME_KEY)
    if(storedUsername) {
      this.username = storedUsername
    }
  },
  methods: {
    async login() {
      if(!this.dataValid) {
        return
      }
      
      this.$loading(true)

      let jwt = ''
      try {
        jwt = await authService.login(this.username, this.password)
        localStorage.setItem(LOCAL_STORAGE_USERNAME_KEY, this.username)
      } finally {
        this.$loading(false)
      }

      let redirectURL = this.redirectRoute + '&jwt=' + jwt

      window.location.replace(redirectURL)
    },
    usernameValidFn(username) {
      const re = /\S+@\S+\.\S+/
      return re.test(username)
    }
  },
  computed: {
    redirectRoute() {
      return this.$route.query.callback || process.env.VUE_APP_DEFAULT_REDIRECT 
    },
    dataValid() {
      return this.usernameValidFn(this.username) && this.password != ''
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.login-container {
  margin: auto;
}

.header {
  color: white;
}

.title {
  font-size: 46px;
  font-weight: bold;
  margin: 10px 0px;
}

.login {
  margin: 50px 10px;
  background-color: white;
  border-radius: 6px;
  padding: 30px;
  text-align: left;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);

  .label {
    color: $primary;
    padding-left: 5px;
    font-size: 14px;
  }
  
  .element {
    position: relative;
    input {
      width: 100%;
      box-sizing: border-box;
      background-color: #ebf0f2;
      border-radius: 6px;
      color: $dark;
      outline: none;
      border: none;
      padding: 10px;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      font-weight: 500;
      
      &:focus {
        box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.forgot-password {
  float: right;
  font-size: 12px;
  color: $primary;
  padding: 5px 0px;
  cursor: pointer;
}


.submit {
  text-align: center;
  margin-top: 50px;
}

.signup-container {
  text-align: center;
  color: rgba($primary, 0.3);
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;

  .signup {
    color: $primary;
    cursor: pointer;
  }
}

@media screen and (min-width: 600px){
  .login-container {
    width: 500px;
  }
}
</style>
