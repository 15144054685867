<template>
  <div class="loginview-container">

    <div style="padding: 50px 10px;">
      <login/>
    </div>

  </div>
</template>

<script>
import Login from '@/components/Login.vue'

export default {
  name: 'LoginView',
  components: {
    Login,
  },
  data() {
    return {
      key: "value"
    }
  },
  methods: {
    name() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared.scss';

.loginview-container {
  background-color: $primary;
  min-height: 100vh;
  position: relative;
}
</style>
